import { css } from '@emotion/react'
import { size } from 'driverama-core/styles/spacing'

export const MultiSelectMenu = (itemsCount?: number) => {
  // TODO refactor this overflow to compare scrollHeight and actual dropdown height
  const isOverflowing =
    itemsCount &&
    itemsCount > 7 &&
    css`
      height: ${size(6)};
      pointer-events: none;

      background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      transform: rotate(180deg);
    `

  return css`
    &:after {
      content: '';
      position: absolute;
      bottom: ${size(0)};
      margin: 0 ${size(1)};

      width: 100%;
      right: 0;

      ${isOverflowing}
    }
  `
}
