import { css } from '@emotion/react'
import { FormControl } from 'driverama-core/components/formControl/FormControl'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { invisibleScrollbars } from 'driverama-core/styles/common'
import { size } from 'driverama-core/styles/spacing'
import { color, time } from 'driverama-core/styles/variables'
import IconSelectDropdown from 'images/icons/IconSelectDropdown.svg'
import { ForwardedRef, forwardRef, useState } from 'react'
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  MenuProps,
  Props,
  SelectInstance
} from 'react-select'
import { MultiSelectMenu } from './Select.styled'
import { SelectOption, SelectProps } from './Select.utils'
import { SelectMenuList } from './SelectMenuList'

function DropdownIndicator<
  Option extends SelectOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: DropdownIndicatorProps<Option, IsMulti, Group>) {
  return (
    <components.DropdownIndicator {...props}>
      <IconSelectDropdown />
    </components.DropdownIndicator>
  )
}

function LimitedMenu<
  Option extends SelectOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: MenuProps<Option, IsMulti, Group>) {
  return (
    <components.Menu {...props} css={MultiSelectMenu(props.options.length)}>
      {props.children}
    </components.Menu>
  )
}

function MenuList<
  Option extends SelectOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: MenuListProps<Option, IsMulti, Group>) {
  return (
    <SelectMenuList
      {...props}
      css={css`
        ${invisibleScrollbars}
      `}
    />
  )
}

function LoadingIndicator() {
  return (
    <SpinnerCentered
      variant="x-small"
      css={css`
        position: absolute;
        right: ${size(3)};
        top: ${size(4)};
      `}
    />
  )
}

export function SelectWithoutRef<
  Option extends SelectOption,
  isMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, isMulti, Group> & SelectProps<SelectOption['value']>,
  ref: ForwardedRef<SelectInstance<Option, isMulti, Group>>
) {
  const hasValue = !!props.value
  const [focused, setFocused] = useState(false)

  const focus = () => setFocused(true)
  const unfocus = () => setFocused(false)

  return (
    <FormControl
      // isMultiselect prop is set when value selected OR input is focused, in order to have consistenly styled select label
      isMultiSelect={hasValue || focused}
      hint={props.hint}
      label={props.label}
      error={props.error}
      isDisabled={props.disabled}
      isRequired={props.isRequired}
      variant={props.variant}
      disabledMode={props.disabledMode}
      className={props.className}
    >
      <ReactSelect
        {...props}
        ref={ref}
        onChange={newValue => {
          props.onBeforeChange?.(newValue)

          if (Array.isArray(newValue) && props.maxItems) {
            if (newValue.length > props.maxItems) {
              return
            }
          }

          props.onChange?.(newValue)
        }}
        id={props.name}
        instanceId={props.name}
        name={props.name}
        menuPlacement={props.menuPlacement ?? 'auto'}
        isDisabled={props.disabled}
        isMulti={props.isMulti}
        isClearable={props.isClearable ?? false}
        components={{
          ...props.components,
          DropdownIndicator,
          LoadingIndicator,
          Menu: LimitedMenu,
          MenuList
        }}
        onFocus={focus}
        onBlur={unfocus}
        styles={{
          container: provided => ({
            ...provided,
            ...props.customStyles?.container,
            position: 'relative',
            flex: 1,

            boxSizing: 'border-box',

            padding: props.label
              ? `calc(${size(2)} - var(--space-height)) var(--left-padding)`
              : `var(--top-padding) var(--left-padding)`,
            paddingTop: props.label && `calc(${size(2)} + var(--label-height))`,

            '&:focus-within svg': {
              transform: 'rotate(180deg)'
            }
          }),
          control: () => ({
            display: 'flex',
            flex: 1
          }),
          input: provided => ({
            ...provided,
            padding: 0
          }),
          indicatorsContainer: provided => {
            return {
              ...provided,
              svg: {
                position: 'absolute',
                right: size(3),
                top: size(4),
                color: props.disabled
                  ? color('night-text')
                  : color('night-l-100'),
                width: size(6),
                height: size(6),

                transition: `all ${time('control')} ease`,
                opacity: props.disabled ? 0.4 : 1
              }
            }
          },
          indicatorSeparator: () => ({ display: 'none' }),
          valueContainer: provided => ({
            ...provided,
            padding: 0
          }),
          singleValue: provided => ({
            ...provided,
            marginLeft: 0,
            marginRight: 0
          }),
          multiValue: provided => ({
            ...provided,
            maxWidth: size(40),
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
          }),
          menu: provided => {
            return { ...provided, right: 0, zIndex: 999 }
          },
          option: (provided, { isDisabled, isFocused, isSelected }) => {
            return {
              ...provided,
              backgroundColor: isSelected
                ? color('night-l-100')
                : isFocused
                ? color('night-l-700')
                : undefined,
              cursor: isDisabled ? 'not-allowed' : 'pointer',
              ':active': {
                ...provided[':active'],
                backgroundColor: !isDisabled ? color('night-l-700') : undefined
              }
            }
          },
          placeholder: provided => ({
            ...provided,
            margin: 0
          }),
          dropdownIndicator: provided => ({
            ...provided,
            padding: 0,
            display: props.isLoading ? 'none' : 'initial'
          }),
          clearIndicator: provided => ({
            ...provided,
            svg: {
              top: 17,
              right: 35,
              width: 20,
              height: 20,
              opacity: 0.6,
              cursor: 'pointer',
              transform: 'none'
            }
          })
        }}
        placeholder={null}
        closeMenuOnSelect={!props.isMulti}
      />
    </FormControl>
  )
}

export const Select = forwardRef(SelectWithoutRef)
