import {
  Children,
  ReactChild,
  ReactFragment,
  ReactPortal,
  useCallback,
  useEffect,
  useRef
} from 'react'
import { GroupBase, MenuListProps } from 'react-select'
import {
  ListChildComponentProps,
  VariableSizeList as List,
  VariableSizeList
} from 'react-window'
import { SelectOption } from './Select.utils'

const OPTION_HEIGHT = 38

type Props = {
  data: ReactChild | ReactFragment | ReactPortal
  index: number
  setSize: (index: number, size: number) => void
}

function getRowHeight(height: number) {
  const remainder = height % 2

  return height + remainder
}

function Row({ data, index, setSize }: Props) {
  const rowRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (rowRef.current) {
      setSize(
        index,
        getRowHeight(rowRef.current.getBoundingClientRect().height)
      )
    }
  }, [setSize, index])

  return <div ref={rowRef}>{data}</div>
}

export function SelectMenuList<
  Option extends SelectOption,
  IsMulti extends boolean = true,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: MenuListProps<Option, IsMulti, Group>) {
  const listRef = useRef<VariableSizeList>(null)
  const sizeMap = useRef<Record<number, number>>({})

  const setSize = useCallback((index: number, size: number) => {
    sizeMap.current = { ...sizeMap.current, [index]: size }
    if (listRef.current) {
      listRef.current.resetAfterIndex(index)
    }
  }, [])

  const getItemSize = (index: number) => sizeMap.current[index] ?? OPTION_HEIGHT

  // we use this to render children directly with all the props and functionality
  const childrenOpts = Children.toArray(props.children)

  const itemsCount = childrenOpts.length

  const listHeight = itemsCount > 0 ? itemsCount * OPTION_HEIGHT : OPTION_HEIGHT

  function renderItem({ style, index }: ListChildComponentProps) {
    return (
      <li style={style} key={index}>
        <Row data={childrenOpts[index]} index={index} setSize={setSize}></Row>
      </li>
    )
  }

  return (
    <List
      ref={listRef}
      style={{ listStyle: 'none', maxHeight: 300 }}
      height={listHeight}
      itemSize={getItemSize}
      width="100%"
      itemCount={itemsCount}
    >
      {renderItem}
    </List>
  )
}
